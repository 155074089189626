import React, {useState} from 'react';
import axios from "axios";
import { withRouter } from "react-router-dom";
import {options} from '../../constants/apiContants';
const url = "https://dayzerokillfeed.com:3000/userDetails/";


function RequestForm(props) {
    const [state , setState] = useState({
        id: "",
        username: "",
        access_token: "",
        userId: "",
        email: "",
        password: "",
        platform: "",
        guildID: "",
        region: "",
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    const sendDetailsToServer = () => {
        if(state.id.length && state.username.length && state.access_token.length && state.userId.length && state.email.length && state.password.length && state.platform.length && state.guildID.length && state.region.length) {
            axios.post("https://dayzerokillfeed.com:3000/users/login",{"email": state.email, "password": state.password})
            .then((res) => {
                if(res.status >= 200 && res.status < 300) {
                    const newToken = res.data.token;
                    axios.post(url, {"id": state.id, "username": state.username, "access_token": state.access_token, "region": state.region, "email": state.email
                    , "userId": state.userId, "platform": state.platform, "guildID": state.guildID, options}, { headers: {'Authorization': 'Bearer '+ newToken }})
                    .then((res) => {
                        if(res.status >= 200 && res.status < 300) {
                            setState(prevState => ({
                                ...prevState,
                                return:'Credentials Saved Successfully',
                                render: res.status.data
                            }))
                            redirectToHome();             
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        redirectToError();
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                redirectToError();
            });
           
        } 
       
        
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        if(!state.id | !state.username | !state.access_token | !state.userId | !state.password | !state.email | !state.platform | !state.guildID| !state.region) {
            redirectToError()    
        } else {
            sendDetailsToServer()
        }
    }

    const redirectToHome = () => {
        props.history.push('/'); 
    }

    const redirectToError = () => {
        props.history.push('/SignupErr'); 
    }

    return(
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            <form>
                <div className="form-group text-left">
                    <label htmlFor="example@example.com">Email</label>
                    <input type="email" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter your email" 
                       value={state.email}
                       onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">Use your registration email</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="abc123">Password</label>
                    <input type="password" 
                       className="form-control" 
                       id="password" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter your password" 
                       value={state.password}
                       onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">Use your registration password</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">User ID</label>
                    <input type="userId" 
                        className="form-control" 
                        id="userId" 
                        placeholder="Enter your K1llFeed User ID"
                        value={state.userId}
                        onChange={handleChange} 
                    />
                    <small id="emailHelp" className="form-text text-muted">You can request this from us if you dont know it.</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Server Username</label>
                    <input type="username" 
                        className="form-control" 
                        id="username" 
                        placeholder="Enter Server username"
                        value={state.username}
                        onChange={handleChange} 
                    />
                    <small id="emailHelp" className="form-text text-muted">(Required) This is the username displayed in FTP credentials.</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="98765">Service ID</label>
                    <input type="id" 
                       className="form-control" 
                       id="id" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter your Nitrado Service ID" 
                       value={state.id}
                       onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">Required</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="98765">Server Region</label>
                    <input type="region" 
                       className="form-control" 
                       id="region" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter your Nitrado Server Region" 
                       value={state.region}
                       onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">(Required) Frankfurt, Los_Angeles, London, Miami, New_York, Singapore, Sydney, Moscow</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Access Token</label>
                    <input type="access_token" 
                        className="form-control" 
                        id="access_token" 
                        placeholder="Enter your Nitrado access token"
                        value={state.access_token}
                        onChange={handleChange} 
                    />
                    <small id="emailHelp" className="form-text text-muted">(Required) We'll never share your credentials with anyone else.</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Discord GuildID/ServerID</label>
                    <input type="guildID" 
                        className="form-control" 
                        id="guildID" 
                        placeholder="Enter your GuildID/ServerID"
                        value={state.guildID}
                        onChange={handleChange} 
                    />
                    <small id="emailHelp" className="form-text text-muted">Required</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="98765">Platform</label>
                    <input type="platform" 
                       className="form-control" 
                       id="platform" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter XBOX, PLAYSTATION or PC" 
                       value={state.platform}
                       onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">(Required) Case Sensitive</small>
                </div>
                <button type="submit" className="btn btn-primary" onClick={handleSubmitClick}>Submit</button>
            </form>
            <div className="alert alert-success mt-2" style={{display: state.successMessage ? 'block' : 'none' }} role="alert">
                {state.successMessage}
            </div>
            
        </div>
    )
}

export default withRouter(RequestForm);
