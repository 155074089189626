import React from 'react';
const Home = (props) => (
    <div className="main-content">
        <div className="container">
            <h2>{props.title}</h2>
            <h4>
                <>The most comprehensive, reliable, affordable and simple to use DayZ Discord Tool for your DayZ Community Server needs.</>
            </h4>
            <div className="home-image"></div>
        </div>
        <div className="container">
            {}
            <div className="thumbnail-container">
                 <div className="home-thumbnail-1"></div>
                    <div className="home-thumbnail-3"></div>
                    <div className="home-thumbnail-2"></div>
                    <div className="home-thumbnail-4"></div>
            </div> 
        </div>
    </div>
);

export default Home;