import Axios from "axios";




export const options = {
    headers: {
        "Authorization": "Bearer",
    }
}
export const newTok = {
		"grant_type": "authorization_code",
		"redirect_uri": "https://localhost:3000",
		"client_id": "1199_aUwtm2svf47mQhboT_wG2hEq1ocy8EEQ1rRe3afae-_YWTh7Oadws1GwVpPY",
		"client_secret": "pLkh8pwnwf2HRymBlFekvCa8-iYKEfx8K8Cmgsohb5Hxt_wV_g8aCgL-YkTjzBAz"	
}

export const urlService = {
	client_id: ""
}

export const oauth = {
	url:'https://oauth.nitrado.net/oauth/v2/auth?redirect_uri=http://localhost:3000&client_id=1199_aUwtm2svf47mQhboT_wG2hEq1ocy8EEQ1rRe3afae-_YWTh7Oadws1GwVpPY&response_type=code&scope=service user_info ssh_keys&state=id10t'
}

export const getData = async urlTok => {
    try {
        const res = await Axios.get('https://oauth.nitrado.net/oauth/v2/token');
        const json = await res.json();
        console.log(json);
    } catch (error) {
        console.log(error);
    }
};



