import React, {useState} from 'react';
import axios from "axios";
import './RegistrationForm.css';
import { withRouter } from "react-router-dom";
const url = "https://dayzerokillfeed.com:3000/users/";

function RegistrationForm(props) {
    const [state , setState] = useState({
        email: "",
        password: "",
        confirmPassword: ""
        // successMessage: null
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    const sendDetailsToServer = () => {
        if(state.email.length && state.password.length) {
            async function signUp() {
                await axios.post(url+"signup",{"email": state.email, "password": state.password})
                .then((res) => {
                    if(res.status >= 200 && res.status < 300) {
                        setState(prevState => ({
                            ...prevState,
                            return:'Registration Successful'
                        }))
                        redirectToHome(); 
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    redirectToError();
                });
            }
            signUp()
            .catch(function (error) {
                console.log(error);
                redirectToError();
            });
        } 
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        if(state.password === state.confirmPassword) {
            sendDetailsToServer()    
        } else {
            redirectToError()
        }
    }

    const redirectToHome = () => {
        props.history.push('/'); 
    }

    const redirectToLogin = () => {
        props.history.push('/Signin'); 
    }

    const redirectToError = () => {
        props.history.push('/SignupErr'); 
    }

    return(
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            <form>
                <div className="form-group text-left">
                <label htmlFor="exampleInputEmail1">Email</label>
                <input type="email" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       placeholder="Register Your Email Address" 
                       value={state.email}
                       onChange={handleChange}
                />
                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input type="password" 
                        className="form-control" 
                        id="password" 
                        placeholder="Create Password"
                        value={state.password}
                        onChange={handleChange} 
                    />
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Confirm Password</label>
                    <input type="password" 
                        className="form-control" 
                        id="confirmPassword" 
                        placeholder="Confirm Password"
                        value={state.confirmPassword}
                        onChange={handleChange} 
                    />
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >
                    Register
                </button>
            </form>
            <div className="alert alert-success mt-2" style={{display: state.successMessage ? 'block' : 'none' }} role="alert">
                {state.successMessage}
            </div>
            <div className="mt-2">
                <span>Already have an account? </span>
                <span className="loginText" onClick={() => redirectToLogin()}>Login here</span> 
            </div>
            
        </div>
    )
}

export default withRouter(RegistrationForm);